import { AppState } from '@/enums/AppState'
import { onboardingPage } from '@/enums/OnboardingPage'
import { PlanItemType } from '@/enums/PlanItemType'
import { JaarPeriode, OefenenPeriode, ToetsenPeriode } from '@/enums/Timespan'
import { Yeargroup } from '@/enums/Yeargroup'
import { IBasisroosterItem } from '@/interfaces/IBasisrooster'
import { IBasisroosterVersie } from '@/interfaces/IBasisroosterVersie'
import { ICandidateResultVM } from '@/interfaces/ICandidateResult'
import { IVolgCategory, IVolgCategoryCollection } from '@/interfaces/ICategory'
import { ICategoryResult, IVolgCategoryResult } from '@/interfaces/ICategoryResult'
import { IDay } from '@/interfaces/IDay'
import { IArchiveDocumentTable, IArchiveFile, IArchiveFileSchoolYearWrapper, IArchiveRequest, IDocument, IDocumentConfig, IDownloadArchiveFileRequest, IExportRequest, ISchoolGroupWrapper, IUploadDocumentForm } from '@/interfaces/IDocument'
import { IKlassenMapExportResponse } from '@/interfaces/IExport'
import { IFilter } from '@/interfaces/IFilter'
import { ICandidateResultFilters, ICategoryResultFilters, IResultFilters } from '@/interfaces/IFilters'
import { IGroup, IGroupsWithBasisrooster, IYearGroup, IYearGroupLabel } from '@/interfaces/IGroup'
import { ILabelKleurDto } from '@/interfaces/ILabelKleur'
import { ILesTemplate, ILesTemplateCollection, IMethodeVerdiepen, ISelectedLesTemplate } from '@/interfaces/ILesTemplate'
import { ILesdag } from '@/interfaces/ILesdag'
import { IEventAfspraakWithAssets, ILesdagSpeciaal } from '@/interfaces/ILesdagSpeciaal'
import { IExtendedNoteInfo, ILabelWithNotes, INote, INoteCandidate, INoteCollection, INoteCollectionOld, INotePlanLes, INoteRelevant, INoteWithLabel, ITag, ITagRecommended } from '@/interfaces/INote'
import { IActiviteitenCollectionPlanning, IAfspraakPost, IAfspraakPostModel, IIngeplandeItems, ILabel, ILabelKleur, IMethodeBlokCollectionPlanning, IMethodeBlokPlanning, IPlanItem, IPlanItemIngepland, ISchoolweken } from '@/interfaces/IPlanning'
import { IPublisher } from '@/interfaces/IPublisher'
import { ILooqingCompetentiesResultCollectionResponse, ILooqingSociaalEmotioneelResultCollectionResponse, IOefeningenResultCollection, IOefeningenResultCollectionHome, IOefeningenResultCollectionResponse, IResult, IResultCollection, IResultCollectionResponseLike, IResultUser, IToetsenResultCollectionResponse } from '@/interfaces/IResult'
import { ISchool } from '@/interfaces/ISchool'
import { ISupplier } from '@/interfaces/ISupplier'
import { IUser, IUserLogin } from '@/interfaces/IUser'
import { IHomepageWidgetsBlurred, IUserSettings, IUserSettingsFilters } from '@/interfaces/IUserSettings'
import { ILabelVerdiepen, IVerdiepenFilters, IVerdiepenItem, IVerdiepenQueryParams } from '@/interfaces/IVerdiepenItem'
import { SelectedNoteMomentType } from '@/store/modules/notesModule'
import { computed } from 'vue'
import { useStore as vuexUseStore } from 'vuex'

export interface IVisiblePlanItem extends IPlanItemIngepland {
    datumStart: Date
    datumEind: Date
    planItemType: PlanItemType
    backgroundColor: string
    borderColor: string
    textColor: string
}

export function useStore() {
    const vuexStore = vuexUseStore()

    const store = vuexStore
    const storeGetters = store.getters

    const appStore = {
        getters: computed(() => {
            return {
                appState: storeGetters.appState() as AppState,
            }
        }),
        commits: {
            setAppstate: (payload: AppState) => store.commit('setAppstate', payload),
        },
        actions: {
            initializeApp: () => store.dispatch('initializeApp') as Promise<any>,
            initializeWidgets: () => store.dispatch('initializeWidgets') as Promise<any>,
        },
    }

    const homeStore = {
        getters: computed(() => {
            return {
                homeNotesLoading: storeGetters.homeNotesLoading() as boolean,
                homeResultsLoading: storeGetters.homeResultsLoading() as boolean,
                homePlanningLoading: storeGetters.homePlanningLoading() as boolean,
                lesdagHome: storeGetters.lesdagHome() as ILesdag[],
                lesdagSpeciaalHome: storeGetters.lesdagSpeciaalHome() as ILesdagSpeciaal[],
                notesHome: storeGetters.notesHome() as INoteWithLabel[] | INoteRelevant[],
                resultsHome: storeGetters.resultsHome() as IOefeningenResultCollectionHome,
            }
        }),
        commits: {
            setHomeLoading: (payload: boolean) => store.commit('setHomeLoading', payload),
            setHomeNotesLoading: (payload: boolean) => store.commit('setHomeNotesLoading', payload),
            setHomePlanningLoading: (payload: boolean) => store.commit('setHomePlanningLoading', payload),
            setHomeResultsLoading: (payload: boolean) => store.commit('setHomeResultsLoading', payload),
            setLesdagTijdHome: (payload: any) => store.commit('setLesdagTijdHome', payload),
            setLesdagHome: (payload: ILesdag[]) => store.commit('setLesdagHome', payload),
            setLesdagSpeciaalHome: (payload: ILesdagSpeciaal[]) => store.commit('setLesdagSpeciaalHome', payload),
            setNotesHome: (payload: INote[]) => store.commit('setNotesHome', payload),
            setResultsHome: (payload: IOefeningenResultCollectionHome[]) => store.commit('setResultsHome', payload),
            pinNoteHome: (payload: INoteWithLabel) => store.commit('pinNoteHome', payload),
            archiveNoteHome: (payload: INoteWithLabel) => store.commit('archiveNoteHome', payload),
        },
        actions: {
            getLesdagHome: () => store.dispatch('getLesdagHome') as Promise<ILesdag[]>,
            getLesdagSpeciaalHome: () => store.dispatch('getLesdagSpeciaalHome') as Promise<ILesdagSpeciaal>,
            getNotesHome: () => store.dispatch('getNotesHome') as Promise<INote[]>,
            getResultsHome: () => store.dispatch('getResultsHome') as Promise<IOefeningenResultCollectionHome[]>,
        },
    }

    const userStore = {
        getters: computed(() => {
            return {
                getUser: storeGetters.getUser() as IUser,
                getUserSettings: storeGetters.getUserSettings() as IUserSettings,
                getUserSettingsPeriod: storeGetters.getUserSettingsPeriod() as IUserSettingsFilters,
                getUserSettingsYeargroup: storeGetters.getUserSettingsYeargroup() as Yeargroup,
                getUserSettingsWidgets: storeGetters.getUserSettingsWidgets() as any,
                getTeachers: storeGetters.getTeachers() as IUser[],
                getRegio: storeGetters.getRegio() as number | null,
                homepageWidgetsBlurred: storeGetters.homepageWidgetsBlurred() as IHomepageWidgetsBlurred,
            }
        }),
        commits: {
            setUser: (payload: IUser) => store.commit('setUser', payload),
            setUserSettings: (payload: any) => store.commit('setUserSettings', payload),
            setUserSettingsWidgets: (payload: any) => store.commit('setUserSettingsWidgets', payload),
            isLoggedIn: (payload: boolean) => store.commit('isLoggedIn', payload),
            setOnboardingFinished: () => store.commit('setOnboardingFinished'),
            setRegio: (payload: number) => store.commit('setRegio', payload),
            setHomepageWidgetsBlurred: (payload: IHomepageWidgetsBlurred) => store.commit('setHomepageWidgetsBlurred', payload),
            updateHomepageWidgetsBlur: (payload: { key: keyof IHomepageWidgetsBlurred; value: boolean }) => store.commit('updateHomepageWidgetsBlur', payload),
        },
        actions: {
            checkAuth: () => store.dispatch('checkAuth') as Promise<IUser>,
            loginUser: () => store.dispatch('loginUser') as Promise<IUser>,
            goToFeedbackPortal: (customUrl?: string) => store.dispatch('goToFeedbackPortal', customUrl) as Promise<null>,
            getUserSettings: () => store.dispatch('getUserSettings') as Promise<IUserSettings>,
            setUserSettings: () => store.dispatch('setUserSettings') as Promise<IUserSettings>,
            setUserSettingsGroup: (payload: any) => store.dispatch('setUserSettingsGroup', payload) as Promise<IUserSettings>,
            setUserSettingsYeargroup: (payload: any) => store.dispatch('setUserSettingsYeargroup', payload) as Promise<IUserSettings>,
            setUserSettingsPeriod: (payload: any) => store.dispatch('setUserSettingsPeriod', payload) as Promise<IUserSettings>,
            setUserSettingsWidgets: (payload: any) => store.dispatch('setUserSettingsWidgets', payload) as Promise<IUserSettings>,
            getTeachers: () => store.dispatch('getTeachers') as Promise<IUser[]>,
            getRegio: () => store.dispatch('getRegio') as Promise<number | null>,
        },
    }

    const resultsStore = {
        getters: computed(() => {
            return {
                getTimespanFilter: storeGetters.getTimespanFilter() as OefenenPeriode,
                getToetsTimespanFilter: storeGetters.getToetsTimespanFilter() as ToetsenPeriode,
                getVolgenTimespanFilter: storeGetters.getVolgenTimespanFilter() as JaarPeriode,
                getYeargroupFilter: storeGetters.getYeargroupFilter() as Yeargroup,
                getResults: storeGetters.getResults as IResult[],
                getResultsOefeningen: storeGetters.getResultsOefeningen as IOefeningenResultCollection,
                getResultsToetsen: storeGetters.getResultsToetsen as IResultCollection,
                getResultsVolgToetsen: storeGetters.getResultsVolgToetsen as IResultCollection,
                getResultsSociaalEmotioneel: storeGetters.getResultsSociaalEmotioneel as IResultCollection,
                getResultsCompetenties: storeGetters.getResultsCompetenties as IResultCollection,
                getResultsLoading: storeGetters.getResultsLoading() as {
                    loading: boolean
                    oefenenLoading: boolean
                    toetsenLoading: boolean
                    volgToetsenLoading: boolean
                    sociaalEmotioneelLoading: boolean
                    competentiesLoading: boolean
                },
                getCategories: storeGetters.getCategories() as ILabel[],
                getCategoryOptions: storeGetters.getCategoryOptions() as {
                    id: number
                    naam: string
                    disabled: boolean
                }[],
                candidateDetailFilters: storeGetters.candidateDetailFilters() as ICandidateResultFilters,
                candidateFilter: storeGetters.candidateFilter() as number,
                candidateResults: storeGetters.candidateResults() as ICandidateResultVM,
                candidateResultsLoading: storeGetters.candidateResultsLoading() as boolean,
                getCatDetailResults: storeGetters.getCatDetailResults() as ICategoryResult,
                getCategoryDetailFilters: storeGetters.getCategoryDetailFilters() as ICategoryResultFilters,
                getSelectedCategoryFilter: storeGetters.getSelectedCategoryFilter() as number,
                getSelectedCategoryPeriodFilter: storeGetters.getSelectedCategoryPeriodFilter() as OefenenPeriode,
                getSelectedVolgCategoryPeriodFilter: storeGetters.getSelectedVolgCategoryPeriodFilter() as JaarPeriode,
                getVolgResultTypes: storeGetters.getVolgResultTypes() as IVolgCategoryCollection,
                getVolgToetsenCategories: storeGetters.getVolgToetsenCategories() as IVolgCategory[],
                getSociaalEmotioneelCategories: storeGetters.getSociaalEmotioneelCategories() as IVolgCategory[],
                getCompetentiesCategories: storeGetters.getCompetentiesCategories() as IVolgCategory[],
                getVolgResults: storeGetters.getVolgResults() as IVolgCategoryResult,
                getResultsDetailsLoading: storeGetters.getResultsDetailsLoading() as boolean,
            }
        }),
        commits: {
            setFilters: (payload: Partial<IResultFilters>) => store.commit('setFilters', payload),
            setResultsLoading: (payload: boolean) => store.commit('setResultsLoading', payload),
            setResults: (payload: IResult[]) => store.commit('setResults', payload),
            setResultsByType: (payload: IResultCollectionResponseLike) => store.commit('setResultsByType', payload),
            setCategories: (payload: ILabel[]) => store.commit('setCategories', payload),
            setFiltersCandidateDetail: (payload: Partial<ICandidateResultFilters>) => store.commit('setFiltersCandidateDetail', payload),
            setCandidateResultsByType: (payload: any) => store.commit('setCandidateResultsByType', payload),
            setOefenResultaten: (payload: IResultUser[]) => store.commit('setOefenResultaten', payload),
            setToetsResultaten: (payload: IResultUser[]) => store.commit('setToetsResultaten', payload),
            setMoelijksteOefeningResultaten: (payload: any[]) => store.commit('setMoelijksteOefeningResultaten', payload),
            setVolgToetsResultaten: (payload: any) => store.commit('setVolgToetsResultaten', payload),
            setSociaalEmotioneelResultaten: (payload: any) => store.commit('setSociaalEmotioneelResultaten', payload),
            setCompetentiesResultaten: (payload: any) => store.commit('setCompetentiesResultaten', payload),
            setFiltersCategoryDetail: (payload: Partial<ICategoryResultFilters>) => store.commit('setFiltersCategoryDetail', payload),
            setVolgResulttypes: (payload: any) => store.commit('setVolgResulttypes', payload),
            setResultDetailsLoading: (payload: boolean) => store.commit('setResultDetailsLoading', payload),
        },
        actions: {
            getOefeningenResultsOverzicht: () => store.dispatch('getOefeningenResultsOverzicht') as Promise<IOefeningenResultCollectionResponse>,
            getVolgToetsenResultsOverzicht: () => store.dispatch('getVolgToetsenResultsOverzicht') as Promise<IToetsenResultCollectionResponse>,
            getMethodeToetsenResultsOverzicht: () => store.dispatch('getMethodeToetsenResultsOverzicht') as Promise<IToetsenResultCollectionResponse>,
            getLooqingSociaalEmotioneelResultsOverzicht: () => store.dispatch('getLooqingSociaalEmotioneelResultsOverzicht') as Promise<ILooqingSociaalEmotioneelResultCollectionResponse>,
            getLooqingCompetentiesResultsOverzicht: () => store.dispatch('getLooqingCompetentiesResultsOverzicht') as Promise<ILooqingCompetentiesResultCollectionResponse>,
            getAllResultsOverzicht: () => store.dispatch('getAllResultsOverzicht') as Promise<void>,
            getCandidateResults: () => store.dispatch('getCandidateResults') as Promise<void>,
            getCandidateVolgResults: () => store.dispatch('getCandidateVolgResults') as Promise<void>,
            getOefeningenResultsVak: (vakLabelId: number) => store.dispatch('getOefeningenResultsVak', vakLabelId) as Promise<any>,
            getToetsResultsVak: (vakLabelId: number) => store.dispatch('getToetsResultsVak', vakLabelId) as Promise<any>,
            getMoelijksteOefeningenResultsVak: (vakLabelId: number) => store.dispatch('getMoelijksteOefeningenResultsVak', vakLabelId) as Promise<any>,
            getVolgtoetsResultsVak: (vakLabelId: number) => store.dispatch('getVolgtoetsResultsVak', vakLabelId) as Promise<any>,
            getSociaalEmotioneelResultsVak: (vakLabelId: number) => store.dispatch('getSociaalEmotioneelResultsVak', vakLabelId) as Promise<any>,
            getCompetentiesResultsVak: (vakLabelId: number) => store.dispatch('getCompetentiesResultsVak', vakLabelId) as Promise<any>,
            getVolgResultTypes: () => store.dispatch('getVolgResultTypes') as Promise<any>,
        },
    }

    const verdiepenStore = {
        getters: computed(() => {
            return {
                verdiepenLoading: storeGetters.verdiepenLoading() as boolean,
                getSearchTextVerdiepen: storeGetters.getSearchTextVerdiepen() as string,
                verdiepenItems: storeGetters.verdiepenItems() as IVerdiepenItem[],
                verdiepenItemsFiltered: storeGetters.verdiepenItemsFiltered() as IVerdiepenItem[],
                verdiepenCategorieen: storeGetters.verdiepenCategorieen() as ILabelVerdiepen[],
                verdiepenMethodes: storeGetters.verdiepenMethodes() as IMethodeVerdiepen[],
                verdiepenVakken: storeGetters.verdiepenVakken() as
                    | ILabel[]
                    | {
                          labelId: number
                          naam: string
                          kleurHexCode: string
                      }[],
                verdiepenJaargroepen: storeGetters.verdiepenJaargroepen() as ILabel[],
                verdiepenFilters: storeGetters.verdiepenFilters() as IVerdiepenFilters,
                favouriteOnly: storeGetters.favouriteOnly() as boolean,
                showAll: storeGetters.showAll() as boolean,
                visibleItems: storeGetters.visibleItems() as number,
            }
        }),
        commits: {
            setVerdiepenLoading: (payload: boolean) => store.commit('setVerdiepenLoading', payload),
            setVerdiepenItems: (payload: IVerdiepenItem[]) => store.commit('setVerdiepenItems', payload),
            appendVerdiepenItems: (payload: IVerdiepenItem[]) => store.commit('appendVerdiepenItems', payload),
            setVerdiepenSearch: (payload: string) => store.commit('setVerdiepenSearch', payload),
            setVerdiepenFiltersFromParams: (payload: IVerdiepenQueryParams) => store.commit('setVerdiepenFiltersFromParams', payload),
            setVerdiepenCategorieen: (payload: ILabelVerdiepen[]) => store.commit('setVerdiepenCategorieen', payload),
            setVerdiepenMethodes: (payload: IMethodeVerdiepen[]) => store.commit('setVerdiepenMethodes', payload),
            setVerdiepenVakken: (payload: ILabel[]) => store.commit('setVerdiepenVakken', payload),
            setVerdiepenJaargroepen: (payload: ILabel[]) => store.commit('setVerdiepenJaargroepen', payload),
            setFavouriteVerdiepenItem: (payload: IVerdiepenItem) => store.commit('setFavouriteVerdiepenItem', payload),
            setVerdiepenFilter: (payload: ILabelVerdiepen) => store.commit('setVerdiepenFilter', payload),
            setFavouriteOnly: (payload: boolean) => store.commit('setFavouriteOnly', payload),
            setShowAll: (payload: boolean) => store.commit('setShowAll', payload),
            setVerdiepenFilters: (payload: ILabel | IMethodeVerdiepen) => store.commit('setVerdiepenFilters', payload),
            setAllVerdiepenFilters: (payload: IVerdiepenFilters) => store.commit('setAllVerdiepenFilters', payload),
            resetMethodeFilter: () => store.commit('resetMethodeFilter'),
            increaseVisibleItems: () => store.commit('increaseVisibleItems'),
            resetVisibleItems: () => store.commit('resetVisibleItems'),
        },
        actions: {
            handleVerdiepenSearch: () => store.dispatch('handleVerdiepenSearch') as Promise<any>,
            setVerdiepenFiltersFromParams: (payload: IVerdiepenQueryParams) => store.dispatch('setVerdiepenFiltersFromParams', payload) as Promise<any>,
            takeMoreVerdiepenItems: () => store.dispatch('takeMoreVerdiepenItems') as Promise<any>,
            getVerdiepenCategorieen: () => store.dispatch('getVerdiepenCategorieen') as Promise<ILabelVerdiepen[]>,
            getVerdiepenMethodes: () => store.dispatch('getVerdiepenMethodes') as Promise<IMethodeVerdiepen[]>,
            getVerdiepenVakken: () => store.dispatch('getVerdiepenVakken') as Promise<ILabel[]>,
            getVerdiepenJaargroepen: () => store.dispatch('getVerdiepenJaargroepen') as Promise<ILabel[]>,
            favouriteVerdiepenItem: (payload: IVerdiepenItem) => store.dispatch('favouriteVerdiepenItem', payload) as Promise<any>,
        },
    }

    const groupStore = {
        getters: computed(() => {
            return {
                groups: storeGetters.groups() as IGroup[],
                candidates: storeGetters.candidates() as IUser[],
                getYeargroups: storeGetters.getYeargroups() as any[],
                getSelectedGroup: storeGetters.getSelectedGroup() as IGroup,
                yeargroupsInGroup: storeGetters.yeargroupsInGroup() as IYearGroupLabel[],
            }
        }),
        commits: {
            setGroups: (payload: IGroup[]) => store.commit('setGroups', payload),
            setCandidates: (payload: IUser[]) => store.commit('setCandidates', payload),
            setYeargroups: (payload: any) => store.commit('setYeargroups', payload),
            setSelectedGroup: (payload: IGroup) => store.commit('setSelectedGroup', payload),
        },
        actions: {
            getGroups: () => store.dispatch('getGroups') as Promise<IGroup[]>,
            getYeargroups: () => store.dispatch('getYeargroups') as Promise<IYearGroupLabel[]>,
            getCandidates: (groupId?: number) => store.dispatch('getCandidates', groupId) as Promise<IUser[]>,
        },
    }

    const lesdagStore = {
        getters: computed(() => {
            return {
                lesdag: storeGetters.lesdag() as { start: Date; end: Date },
                getLesdag: storeGetters.getLesdag() as ILesdag[],
                getLesdagSpeciaal: storeGetters.getLesdagSpeciaal() as ILesdagSpeciaal,
                les: (id: number) => storeGetters.les(id) as ILesdag | null,
                availableYeargroupsLesdag: storeGetters.availableYeargroupsLesdag() as ILabel[],
                lesdagLoading: storeGetters.lesdagLoading() as boolean,
            }
        }),
        commits: {
            setLesdagTijd: (payload: { start: Date; end: Date }) => store.commit('setLesdagTijd', payload),
            setLesdag: (payload: ILesdag[]) => store.commit('setLesdag', payload),
            setLesdagenSpeciaal: (payload: ILesdagSpeciaal[]) => store.commit('setLesdagenSpeciaal', payload),
            setLesdagLoading: (payload: boolean) => store.commit('setLesdagLoading', payload),
        },
        actions: {
            getLesdagen: () => store.dispatch('getLesdagen') as Promise<ILesdag[]>,
            getLesdagenSpeciaal: () => store.dispatch('getLesdagenSpeciaal') as Promise<ILesdagSpeciaal>,
            getAfspraakdetails: (id: number) => store.dispatch('getAfspraakdetails', id) as Promise<ILesdagSpeciaal[]>,
        },
    }

    const labelStore = {
        getters: computed(() => {
            return {
                vakLabels: storeGetters.vakLabels() as ILabel[],
                notitieLabels: storeGetters.notitieLabels() as ILabel[],
                verdiepenLabels: storeGetters.verdiepenLabels() as ILabel[],
                alleJaargroepenLabels: storeGetters.alleJaargroepenLabels() as ILabel[],
                jaargroepenLabels: storeGetters.jaargroepenLabels() as ILabel[],
                standaardLabelKleuren: storeGetters.standaardLabelKleuren() as ILabel[],
                labelColorsLoading: storeGetters.labelColorsLoading() as boolean,
            }
        }),
        commits: {
            setVakLabels: (payload: ILabel[]) => store.commit('setVakLabels', payload),
            setNotitieLabels: (payload: ILabel[]) => store.commit('setNotitieLabels', payload),
            setVerdiepenLabels: (payload: ILabel[]) => store.commit('setVerdiepenLabels', payload),
            setAlleJaargroepenLabels: (payload: ILabel[]) => store.commit('setAlleJaargroepenLabels', payload),
            setJaargroepenLabels: (payload: ILabel[]) => store.commit('setJaargroepenLabels', payload),
            setStandaardLabelKleuren: (payload: ILabel[]) => store.commit('setStandaardLabelKleuren', payload),
            setLabelColorsLoading: (payload: boolean) => store.commit('setLabelColorsLoading', payload),
        },
        actions: {
            getVakLabels: () => store.dispatch('getVakLabels') as Promise<any>,
            getNotitieLabels: () => store.dispatch('getNotitieLabels') as Promise<any>,
            getVerdiepenLabels: () => store.dispatch('getVerdiepenLabels') as Promise<any>,
            getAlleJaargroepenLabels: () => store.dispatch('getAlleJaargroepenLabels') as Promise<any>,
            getJaargroepenLabels: () => store.dispatch('getJaargroepenLabels') as Promise<any>,
            setCustomLabelKleuren: (payload: ILabelKleurDto[]) => store.dispatch('setCustomLabelKleuren', payload) as Promise<any>,
            getStandaardLabelKleuren: () => store.dispatch('getStandaardLabelKleuren') as Promise<any>,
            resetAllLabelColors: (creatorType: 1 | 2) => store.dispatch('resetAllLabelColors', creatorType) as Promise<any>,
            resetLabelColors: (labelId: number, creatorType: 1 | 2) => store.dispatch('resetLabelColors', { labelId, creatorType }) as Promise<any>,
        },
    }

    const planningStore = {
        getters: computed(() => {
            return {
                templatesEnLessen: storeGetters.templatesEnLessen() as IActiviteitenCollectionPlanning[],
                methodeBlokken: storeGetters.methodeBlokken() as IMethodeBlokCollectionPlanning[],
                schoolweken: storeGetters.schoolweken() as ISchoolweken,
                planning: storeGetters.planning() as IPlanItem[],
                ingeplandeItems: storeGetters.ingeplandeItems() as IIngeplandeItems,
                yeargroupsPlanning: storeGetters.yeargroupsPlanning() as IYearGroupLabel[],
                availableYeargroupsPlanning: storeGetters.availableYeargroupsPlanning() as IYearGroupLabel[],
                currentDateRange: storeGetters.currentDateRange() as { start: Date; end: Date },
                visiblePlanning: storeGetters.visiblePlanning() as IPlanItem[],
                visiblePlanItems: storeGetters.visiblePlanItems() as IIngeplandeItems,
                vakgebiedKleuren: storeGetters.vakgebiedKleuren() as ILabelKleur[],
                calendarAttributesPlanning: storeGetters.calendarAttributesPlanning() as any[],
                planningLoading: storeGetters.planningLoading() as boolean,
                labelsMetNotities: storeGetters.labelsMetNotities() as ILabelWithNotes[],
            }
        }),
        commits: {
            cleanPlanning: () => store.commit('cleanPlanning'),
            setTemplatesEnLessen: (payload: IActiviteitenCollectionPlanning[]) => store.commit('setTemplatesEnLessen', payload),
            setMethodeBlokken: (payload: IMethodeBlokCollectionPlanning[]) => store.commit('setMethodeBlokken', payload),
            setPlanningLoading: (payload: boolean) => store.commit('setPlanningLoading', payload),
            setSchoolweken: (payload: ISchoolweken) => store.commit('setSchoolweken', payload),
            setPlanning: (payload: IPlanItem[]) => store.commit('setPlanning', payload),
            setIngeplandeItems: (payload: IIngeplandeItems) => store.commit('setIngeplandeItems', payload),
            setCurrentDateRange: (payload: { start: Date; end: Date }) => store.commit('setCurrentDateRange', payload),
            setVakgebiedKleuren: (payload: ILabelKleur[]) => store.commit('setVakgebiedKleuren', payload),
            setYeargroupLabels: (payload: IYearGroupLabel[]) => store.commit('setYeargroupLabels', payload),
            resetYeargroupLabels: () => store.commit('resetYeargroupLabels'),
            setLabelsMetNotities: (payload: ILabelWithNotes[]) => store.commit('setLabelsMetNotities', payload),
        },
        actions: {
            getMethodeBlokken: () => store.dispatch('getMethodeBlokken') as Promise<IMethodeBlokPlanning[]>,
            calculateJaarplanning: () => store.dispatch('calculateJaarplanning') as Promise<any>,
            getSchoolweken: () => store.dispatch('getSchoolweken') as Promise<any>,
            getPlanning: () => store.dispatch('getPlanning') as Promise<any>,
            getPlanningFull: () => store.dispatch('getPlanningFull') as Promise<any>,
            getIngeplandeItems: () => store.dispatch('getIngeplandeItems') as Promise<any>,
            getLabelKleuren: () => store.dispatch('getLabelKleuren') as Promise<any>,
            getYeargroupLabels: () => store.dispatch('getYeargroupLabels') as Promise<IYearGroupLabel[]>,
            postPlanitem: (payload: IPlanItem) => store.dispatch('postPlanitem', payload) as Promise<any>,
            planItemDoorschuiven: (payload: any) => store.dispatch('planItemDoorschuiven', payload) as Promise<any>,
            planItemReeksDoorschuiven: (payload: any) => store.dispatch('planItemReeksDoorschuiven', payload) as Promise<any>,
            planItemReeksCorrigeren: (payload: IPlanItem) => store.dispatch('planItemReeksCorrigeren', payload) as Promise<any>,
            planItemCorrigeren: (payload: any) => store.dispatch('planItemCorrigeren', payload) as Promise<any>,
            planItemVerwijderen: (payload: any) => store.dispatch('planItemVerwijderen', payload) as Promise<any>,
            postAfspraak: (payload: IAfspraakPost) => store.dispatch('postAfspraak', payload) as Promise<any>,
            putAfspraak: (payload: IAfspraakPost) => store.dispatch('putAfspraak', payload) as Promise<any>,
            deleteAfspraak: (id: number) => store.dispatch('deleteAfspraak', id) as Promise<any>,
            deleteAfspraakAfbeelding: (id: number) => store.dispatch('deleteAfspraakAfbeelding', id) as Promise<any>,
            getTemplatesEnLessen: () => store.dispatch('getTemplatesEnLessen') as Promise<any>,
            getActiviteitDetails: (id: number) => store.dispatch('getActiviteitDetails', id) as Promise<any>,
            getLabelsMetNotities: () => store.dispatch('getLabelsMetNotities') as Promise<any>,
            getToekomstigeLessenVoorVak: (id: number) => store.dispatch('getToekomstigeLessenVoorVak', id) as Promise<any>,
            getIngeplandeAfsprakenVoorVak: (vakLabelId: number) => store.dispatch('getIngeplandeAfsprakenVoorVak', vakLabelId) as Promise<any>,
        },
    }

    const notesStore = {
        getters: computed(() => {
            return {
                getNotificationHtml: storeGetters.getNotificationHtml() as string,
                getNotificationState: storeGetters.getNotificationState() as boolean,
                getSidebarState: storeGetters.getSidebarState() as boolean,
                getCurrentNote: storeGetters.getCurrentNote() as INoteWithLabel,
                getCurrentNoteHtml: storeGetters.getCurrentNoteHTML() as string,
                getSearchText: storeGetters.getSearchText() as string,
                getKeywords: storeGetters.getKeywords() as string[],
                getFilters: storeGetters.getFilters() as IFilter[],
                pinnedOnly: storeGetters.pinnedOnly() as boolean,
                showArchived: storeGetters.showArchived() as boolean,
                getNotes: storeGetters.getNotes() as INoteWithLabel[],
                getFilteredNotes: storeGetters.getFilteredNotes() as INoteWithLabel[],
                getNotesByDate: storeGetters.getNotesByDate() as INoteCollection,
                getNotesByOlderDate: storeGetters.getNotesByOlderDate() as INoteCollectionOld,
                getNoteSubjects: storeGetters.getNoteSubjects() as ILabel[],
                getNoteCandidates: storeGetters.getNoteCandidates() as INoteCandidate[],
                getRecommendedTags: storeGetters.getRecommendedTags() as ITagRecommended[],
                getPageSpecificRecommendedTags: storeGetters.getPageSpecificRecommendedTags() as ITagRecommended[],
                getCombinedRecommendedTags: storeGetters.getCombinedRecommendedTags() as ITagRecommended[],
                getAllTags: storeGetters.getAllTags() as ITag[],
                getSidebarPlanNoteOnly: storeGetters.getSidebarPlanNoteOnly() as boolean,
                getNoteMomentDate: storeGetters.getNoteMomentDate() as Date | null | undefined,
                getNoteMomentVaklabelId: storeGetters.getNoteMomentVaklabelId() as number | null | undefined,
                getNoteMomentActiviteitLabelId: storeGetters.getNoteMomentActiviteitLabelId() as number | null | undefined,
                getSelectedNoteMomentType: storeGetters.getSelectedNoteMomentType() as SelectedNoteMomentType | null,
                getSelectedNoteMomentLessonCount: storeGetters.getSelectedNoteMomentLessonCount() as number,
                getNoteMomentPlanItem: storeGetters.getNoteMomentPlanItem() as INotePlanLes | null,
                getNoteMomentLabel: storeGetters.getNoteMomentLabel() as ILabel | null,
                getCurrentNoteLesInfo: storeGetters.getCurrentNoteLesInfo() as INotePlanLes | null,
                getCurrentNoteExtendedInfo: storeGetters.getCurrentNoteExtendedInfo() as IExtendedNoteInfo | null,
                getNoteMomentVakgebied: storeGetters.getNoteMomentVakgebied() as number | null,
            }
        }),
        commits: {
            setNotificationState: (payload: boolean) => store.commit('setNotificationState', payload),
            setNotificationHtml: (payload: string) => store.commit('setNotificationHtml', payload),
            setSidebarState: (payload: boolean) => store.commit('setSidebarState', payload),
            setPlanNoteSidebarState: () => store.commit('setPlanNoteSidebarState'),
            setCurrentNote: (payload: INoteWithLabel) => store.commit('setCurrentNote', payload),
            resetCurrentNote: (payload?: INoteWithLabel) => store.commit('resetCurrentNote'),
            setCurrentNoteHtml: (payload: string) => store.commit('setCurrentNoteHTML', payload),
            setNotesSearch: (payload: string) => store.commit('setNotesSearch', payload),
            setKeywords: (payload: string[]) => store.commit('setKeywords', payload),
            setTagFilter: (payload: IFilter) => store.commit('setTagFilter', payload),
            setTagFilters: (payload: IFilter[]) => store.commit('setTagFilters', payload),
            setPinnedOnly: (payload: boolean) => store.commit('setPinnedOnly', payload),
            setShowArchived: (payload: boolean) => store.commit('setShowArchived', payload),
            setNotes: (payload: INoteWithLabel[]) => store.commit('setNotes', payload),
            setNoteSubjects: (payload: ILabel[]) => store.commit('setNoteSubjects', payload),
            setNoteCandidates: (payload: INoteCandidate[]) => store.commit('setNoteCandidates', payload),
            setAllTags: (payload: ITag[]) => store.commit('setAllTags', payload),
            setRecommendedTags: (payload: ITag[]) => store.commit('setRecommendedTags', payload),
            setRecommendedTagActiveState: (payload: ITagRecommended) => store.commit('setRecommendedTagActiveState', payload),
            setPageSpecificRecommendedTags: (payload: ITagRecommended[]) => store.commit('setPageSpecificRecommendedTags', payload),
            removePageSpecificRecommendedTags: () => store.commit('removePageSpecificRecommendedTags'),
            pinNote: (payload: INoteWithLabel) => store.commit('pinNote', payload),
            setCurrentNoteMomentDate: (payload: Date | null) => store.commit('setCurrentNoteMomentDate', payload),
            setCurrentNoteMomentPlanItem: (payload: INotePlanLes | null) => store.commit('setCurrentNoteMomentPlanItem', payload),
            setCurrentNoteVak: (payload: ILabel | null) => store.commit('setCurrentNoteVak', payload),
            setCurrentNoteLessons: (payload: ILabel | null) => store.commit('setCurrentNoteLessons', payload),
            setCurrentNoteLesInfo: (payload: INotePlanLes | null) => store.commit('setCurrentNoteLesInfo', payload),
            resetCurrentNoteMoment: () => store.commit('resetCurrentNoteMoment'),
            setExtendedNoteInfo: (payload: IExtendedNoteInfo) => store.commit('setExtendedNoteInfo', payload),
        },
        actions: {
            getNotes: () => store.dispatch('getNotes') as Promise<INote[]>,
            createNote: (payload: INoteWithLabel) => store.dispatch('createNote', payload) as Promise<any>,
            updateNote: (payload: INoteWithLabel) => store.dispatch('updateNote', payload) as Promise<any>,
            deleteNote: (id: number) => store.dispatch('deleteNote', id) as Promise<any>,
            deleteRecommendedTag: (id: number) => store.dispatch('deleteRecommendedTag', id) as Promise<any>,
            getNoteSubjects: () => store.dispatch('getNoteSubjects') as Promise<ILabel[]>,
            getNoteCandidates: () => store.dispatch('getNoteCandidates') as Promise<INoteCandidate[]>,
            getRecommendedTags: () => store.dispatch('getRecommendedTags') as Promise<INote[]>,
            getAllTags: () => store.dispatch('getAllTags') as Promise<INote[]>,
            setCurrentNoteMomentDate: (payload: Date | null) => store.dispatch('setCurrentNoteMomentDate', payload) as Promise<any>,
            setCurrentNoteMomentPlanItem: (payload: IPlanItem | null) => store.dispatch('setCurrentNoteMomentPlanItem', payload) as Promise<any>,
            setCurrentNoteVak: (payload: ILabel | null) => store.dispatch('setCurrentNoteVak', payload) as Promise<any>,
            setCurrentNoteLessons: (payload: INotePlanLes[] | null) => store.dispatch('setCurrentNoteLessons', payload) as Promise<any>,
            openCreateNoteForLesson: (payload: IPlanItem) => store.dispatch('openCreateNoteForLesson', payload) as Promise<any>,
            openCreateNoteForLessonYourday: (payload: ILesdag) => store.dispatch('openCreateNoteForLessonYourday', payload) as Promise<any>,
            openCreateNoteForLessonAfspraak: (payload: IAfspraakPostModel) => store.dispatch('openCreateNoteForLessonAfspraak', payload) as Promise<any>,
            openCreateNoteForAfspraakYourday: (payload: IEventAfspraakWithAssets) => store.dispatch('openCreateNoteForAfspraakYourday', payload) as Promise<any>,
            getCurrentNoteExtendedInfo: (payload: number) => store.dispatch('getCurrentNoteExtendedInfo', payload) as Promise<any>,
        },
    }

    const onboardingStore = {
        getters: computed(() => {
            return {
                page: storeGetters.page() as onboardingPage,
                basisroosterLoaded: storeGetters.basisroosterLoaded() as boolean,
                hasEditedBasisrooster: storeGetters.hasEditedBasisrooster() as boolean,
                holidays: storeGetters.holidays() as IDay[],
                studydays: storeGetters.studydays() as IDay[],
                activities: storeGetters.activities() as IDay[],
                tests: storeGetters.tests() as IDay[],
                calendarAttributes: storeGetters.calendarAttributes() as any[],
                regios: storeGetters.regios() as any[],
                lesTemplates: storeGetters.lesTemplates() as ILesTemplateCollection[],
                selectedLesTemplates: storeGetters.selectedLesTemplates() as ISelectedLesTemplate[],
                basisrooster: storeGetters.basisrooster() as IBasisroosterItem[],
                basisroosterVisible: storeGetters.basisroosterVisible() as IBasisroosterItem[],
                basisroosterBlocks: storeGetters.basisroosterBlocks() as IBasisroosterItem[],
                availableYeargroups: storeGetters.availableYeargroups() as IYearGroup[],
                selectedYeargroup: storeGetters.selectedYeargroup() as IYearGroup | null,
                onboardingLoading: storeGetters.onboardingLoading() as boolean,
                vakActiviteitLabels: storeGetters.vakActiviteitLabels() as ILabel[],
                flattenedTemplates: storeGetters.flattenedTemplates() as ILesTemplate[],
                schooljarenWithRooster: storeGetters.schooljarenWithRooster() as IGroupsWithBasisrooster[],
                basisroosterVersies: storeGetters.basisroosterVersies() as IBasisroosterVersie[],
                basisroosterVersieTitle: storeGetters.basisroosterVersieTitle() as string,
                useBasisroosterOnSave: storeGetters.useBasisroosterOnSave() as boolean,
                basisroosterVersieId: storeGetters.basisroosterVersieId() as number | null,
                hasRevertedBasisrooster: storeGetters.hasRevertedBasisrooster() as boolean,
                basisroosterVersieLoading: storeGetters.basisroosterVersiesLoading() as boolean,
            }
        }),
        commits: {
            setHasEditedBasisrooster: (payload: boolean) => store.commit('setHasEditedBasisrooster', payload),
            setPage: (payload: onboardingPage) => store.commit('setPage', payload),
            setOnboardingLoading: (payload: boolean) => store.commit('setOnboardingLoading', payload),
            changeHoliday: (payload: IDay) => store.commit('changeHoliday', payload),
            addHoliday: (payload: IDay) => store.commit('addHoliday', payload),
            deleteHoliday: (id: string) => store.commit('deleteHoliday', id),
            addStudyday: (payload: IDay) => store.commit('addStudyday', payload),
            changeStudyday: (payload: IDay) => store.commit('changeStudyday', payload),
            deleteStudyday: (id: string) => store.commit('deleteStudyday', id),
            addTest: (payload: IDay) => store.commit('addTest', payload),
            changeTest: (payload: IDay) => store.commit('changeTest', payload),
            deleteTest: (id: string) => store.commit('deleteTest', id),
            addActivity: (payload: IDay) => store.commit('addActivity', payload),
            changeActivity: (payload: IDay) => store.commit('changeActivity', payload),
            deleteActivity: (id: string) => store.commit('deleteActivity', id),
            setRegios: (payload: any[]) => store.commit('setRegios', payload),
            setHolidays: (payload: IDay[]) => store.commit('setHolidays', payload),
            setStudydays: (payload: IDay[]) => store.commit('setStudydays', payload),
            setTests: (payload: IDay[]) => store.commit('setTests', payload),
            setActivities: (payload: IDay[]) => store.commit('setActivities', payload),
            setTemplates: (payload: ILesTemplateCollection[]) => store.commit('setTemplates', payload),
            setSelectedTemplates: (payload: ISelectedLesTemplate[]) => store.commit('setSelectedTemplates', payload),
            updateSelectedTemplates: (payload: ISelectedLesTemplate) => store.commit('updateSelectedTemplates', payload),
            removeSelectedTemplate: (payload: ISelectedLesTemplate) => store.commit('removeSelectedTemplate', payload),
            addBasisroosterItem: (payload: IBasisroosterItem) => store.commit('addBasisroosterItem', payload),
            updateBasisroosterItem: (payload: IBasisroosterItem) => store.commit('updateBasisroosterItem', payload),
            updateBasisroosterItemCalendar: (payload: any) => store.commit('updateBasisroosterItemCalendar', payload),
            removeBasisroosterItem: (payload: any) => store.commit('removeBasisroosterItem', payload),
            updateBasisroosterItemTemplateChange: (payload: ISelectedLesTemplate) => store.commit('updateBasisroosterItemTemplateChange', payload),
            updateBasisroosterItemDefId: (ids: any) => store.commit('updateBasisroosterItemDefId', ids),
            removeBasisroosterItemTemplateChange: (payload: IBasisroosterItem) => store.commit('removeBasisroosterItemTemplateChange', payload),
            setBasisrooster: (payload: IBasisroosterItem[]) => store.commit('setBasisrooster', payload),
            setBasisroosterLoaded: (payload: boolean) => store.commit('setBasisroosterLoaded', payload),
            setRoosterItems: (payload: IBasisroosterItem[]) => store.commit('setRoosterItems', payload),
            cleanCustomBlocks: () => store.commit('cleanCustomBlocks'),
            addRoosterItem: (payload: IBasisroosterItem) => store.commit('addRoosterItem', payload),
            updateRoosterItem: (payload: IBasisroosterItem) => store.commit('updateRoosterItem', payload),
            removeRoosterItem: (payload: IBasisroosterItem) => store.commit('removeRoosterItem', payload),
            addBasisroosterBlocks: (payload: IBasisroosterItem[]) => store.commit('addBasisroosterBlocks', payload),
            setAvailableYeargroups: (payload: IYearGroup[]) => store.commit('setAvailableYeargroups', payload),
            setSelectedYeargroup: (payload: IYearGroup | null) => store.commit('setSelectedYeargroup', payload),
            setVakActiviteitLabels: (payload: ILabel[]) => store.commit('setVakActiviteitLabels', payload),
            setSchooljarenWithRoosters: (payload: IGroupsWithBasisrooster[]) => store.commit('setSchooljarenWithRoosters', payload),
            setBasisroosterVersies: (payload: IBasisroosterVersie[]) => store.commit('setBasisroosterVersies', payload),
            setBasisroosterVersieNaam: (payload: string | null) => store.commit('setBasisroosterVersieNaam', payload),
            setUseBasisroosterOnSave: (payload: boolean) => store.commit('setUseBasisroosterOnSave', payload),
            setBasisroosterVersieId: (payload: number | null) => store.commit('setBasisroosterVersieId', payload),
            setHasRevertedBasisrooster: (payload: boolean) => store.commit('setHasRevertedBasisrooster', payload),
        },
        actions: {
            getRegios: () => store.dispatch('getRegios') as Promise<any>,
            getVoorwaarden: (params: any) => store.dispatch('getVoorwaarden', params) as Promise<any>,
            basispoortToestemming: () => store.dispatch('basispoortToestemming') as Promise<any>,
            getHolidays: () => store.dispatch('getHolidays') as Promise<any>,
            updateHoliday: (day: IDay) => store.dispatch('updateHoliday', day) as Promise<any>,
            createHoliday: (day: IDay) => store.dispatch('createHoliday', day) as Promise<any>,
            deleteHoliday: (dayid: number) => store.dispatch('deleteHoliday', dayid) as Promise<any>,
            getStudydays: () => store.dispatch('getStudydays') as Promise<any>,
            createStudyday: (day: IDay) => store.dispatch('createStudyday', day) as Promise<any>,
            updateStudyday: (day: IDay) => store.dispatch('updateStudyday', day) as Promise<any>,
            deleteStudyday: (dayid: number) => store.dispatch('deleteStudyday', dayid) as Promise<any>,
            getActivities: () => store.dispatch('getActivities') as Promise<any>,
            createActivity: (day: IDay) => store.dispatch('createActivity', day) as Promise<any>,
            updateActivity: (day: IDay) => store.dispatch('updateActivity', day) as Promise<any>,
            deleteActivity: (dayid: number) => store.dispatch('deleteActivity', dayid) as Promise<any>,
            getTests: () => store.dispatch('getTests') as Promise<any>,
            createTest: (day: IDay) => store.dispatch('createTest', day) as Promise<any>,
            updateTest: (day: IDay) => store.dispatch('updateTest', day) as Promise<any>,
            deleteTest: (dayid: number) => store.dispatch('deleteTest', dayid) as Promise<any>,
            getTemplates: (force?: boolean) => store.dispatch('getTemplates', force) as Promise<any>,
            getSelectedTemplates: () => store.dispatch('getSelectedTemplates') as Promise<any>,
            putSelectedTemplates: () => store.dispatch('putSelectedTemplates') as Promise<any>,
            putCopySelectedTemplates: (data: any) => store.dispatch('putCopySelectedTemplates', data) as Promise<any>,
            getBasisrooster: () => store.dispatch('getBasisrooster') as Promise<any>,
            postBasisroosterStamgroep: (payload?: { versieTitel?: string; useBasisroosterOnSave?: boolean; versionId?: number }) => store.dispatch('postBasisroosterStamgroep', payload) as Promise<any>,
            getVakActiviteitLabels: () => store.dispatch('getVakActiviteitLabels') as Promise<any>,
            getBasisroosterVersies: () => store.dispatch('getBasisroosterVersies') as Promise<any>,
            revertBasisroosterVersie: (versieId: number) => store.dispatch('revertBasisroosterVersie', versieId) as Promise<any>,
            undoRevertBasisroosterVersie: () => store.dispatch('undoRevertBasisroosterVersie') as Promise<any>,
            resetBasisroosterVersie: () => store.dispatch('resetBasisroosterVersie') as Promise<any>,
        },
    }

    const appcuesStore = {
        getters: computed(() => {}),
        commits: {},
        actions: {
            appcuesPage: () => store.dispatch('appcuesPage') as Promise<any>,
            appcuesIdentify: (customprops?: any) => store.dispatch('appcuesIdentify', customprops) as Promise<any>,
            appcuesTrack: (customprops: any) => store.dispatch('appcuesTrack', customprops) as Promise<any>,
        },
    }

    const settingsStore = {
        getters: computed(() => {
            return {
                schoolInfo: storeGetters.schoolInfo() as ISchool,
                leveranciers: storeGetters.leveranciers() as {
                    availableLeveranciers: ISupplier[]
                    chosenLeverancier: ISupplier
                },
                uitgevers: storeGetters.uitgevers() as IPublisher[],
                lastLogins: storeGetters.lastLogins() as {
                    naam: string
                    voornaam: string
                    tussenvoegsel: string
                    achternaam: string
                    type: string
                    laatstIngelogdOp: string
                }[],
                pollingState: storeGetters.pollingState() as boolean,
                schoolregios: storeGetters.schoolregios() as any[],
                verwerkersovereenkomst: storeGetters.verwerkersovereenkomst() as string | number | null,
                verwerkersOvereenkomstOk: storeGetters.verwerkersOvereenkomstOk() as boolean,
                showVerwerkersBtn: storeGetters.showVerwerkersBtn() as boolean,
            }
        }),
        commits: {
            setSchool: (payload: ISchool) => store.commit('setSchool', payload),
            setLeveranciers: (payload: { availableLeveranciers: ISupplier[]; chosenLeverancier: ISupplier }) => store.commit('setLeveranciers', payload),
            setUitgevers: (payload: IPublisher[]) => store.commit('setUitgevers', payload),
            updateLeverancier: (payload: ISupplier) => store.commit('updateLeverancier', payload),
            setLastlogins: (payload: IUserLogin[]) => store.commit('setLastlogins', payload),
            setPollingState: (payload: boolean) => store.commit('setPollingState', payload),
            setVerwerkersOvereenkomst: (payload: string | number | null) => store.commit('setVerwerkersOvereenkomst', payload),
            setVerwerkersOvereenkomstOk: (payload: boolean) => store.commit('setVerwerkersOvereenkomstOk', payload),
            setSchoolRegios: (payload: any[]) => store.commit('setSchoolRegios', payload),
            setVerwerkersOvereenkomstBtn: (payload: boolean) => store.commit('setVerwerkersOvereenkomstBtn', payload),
        },
        actions: {
            getSchool: () => store.dispatch('getSchool') as Promise<any>,
            getLeveranciers: () => store.dispatch('getLeveranciers') as Promise<any>,
            getUitgevers: () => store.dispatch('getUitgevers') as Promise<any>,
            getVerwerkersOvereenkomst: () => store.dispatch('getVerwerkersOvereenkomst') as Promise<any>,
            getSchoolRegios: () => store.dispatch('getSchoolRegios') as Promise<any>,
            getLastLogins: () => store.dispatch('getLastLogins') as Promise<any>,
            setLeverancier: () => store.dispatch('setLeverancier') as Promise<any>,
        },
    }

    const documentsStore = {
        getters: computed(() => {
            return {
                getDocuments: storeGetters.getDocuments() as IDocument[],
                getDocumentsLoading: storeGetters.getDocumentsLoading() as boolean,
                getDocumentConfig: storeGetters.getDocumentConfig() as IDocumentConfig,
                getDocumentsConfigLoading: storeGetters.getDocumentsConfigLoading() as boolean,
                getArchiveDocuments: storeGetters.getArchiveDocuments() as IArchiveFile[],
            }
        }),
        commits: {
            setDocuments: (payload: IDocument[]) => store.commit('setDocuments', payload),
            addDocument: (payload: IDocument) => store.commit('addDocument', payload),
            setDocumentsLoading: (payload: boolean) => store.commit('setDocumentsLoading', payload),
            setDocumentConfig: (payload: IDocumentConfig) => store.commit('setDocumentConfig', payload),
            setDocumentsConfigLoading: (payload: boolean) => store.commit('setDocumentsConfigLoading', payload),
            setArchiveDocuments: (payload: IArchiveFile[]) => store.commit('setArchivedDocuments', payload),
        },
        actions: {
            getDocumenten: (groupId: number) => store.dispatch('getDocumenten', groupId) as Promise<any>,
            downloadDocument: (payload: { groupId: number; document: IDocument }) => store.dispatch('downloadDocument', payload) as Promise<any>,
            downloadDocumentArchive: (payload: { groupId: number; document: IArchiveDocumentTable }) => store.dispatch('downloadDocumentArchive', payload) as Promise<any>,
            uploadDocument: (payload: { documentForm: IUploadDocumentForm; groupId: number }) => store.dispatch('uploadDocument', payload) as Promise<any>,
            deleteDocument: (payload: { groupId: number; document: IDocument }) => store.dispatch('deleteDocument', payload) as Promise<any>,
            getConfig: (groupId: number) => store.dispatch('getConfig', groupId) as Promise<any>,
            getArchivedDocuments: (groupId: number) => store.dispatch('getArchivedDocuments', groupId) as Promise<IArchiveFile[]>,
        },
    }

    const exportStore = {
        getters: computed(() => {
            return {
                exportLoading: storeGetters.getExportLoading() as boolean,
            }
        }),
        commits: {
            setExportLoading: (payload: boolean) => store.commit('setExportLoading', payload),
        },
        actions: {
            exportOverview: () => store.dispatch('exportOverview') as Promise<ISchoolGroupWrapper[]>,
            exportKlassenMap: (payload: { request: IExportRequest; schoolJaarLabel: string }) => store.dispatch('exportKlassenMap', payload) as Promise<IKlassenMapExportResponse>,
            generateArchiveFIleList: (payload: IArchiveRequest) => store.dispatch('generateArchiveFileList', payload) as Promise<IArchiveFileSchoolYearWrapper[]>,
            getArchiveFileList: (payload: IArchiveRequest) => store.dispatch('getArchiveFileList', payload) as Promise<IArchiveFileSchoolYearWrapper[]>,
            downloadArchiveFiles: (payload: { request: IDownloadArchiveFileRequest[]; schoolJaarLabel: string }) => store.dispatch('downloadArchiveFiles', payload) as Promise<Blob>,
        },
    }

    return {
        appStore,
        lesdagStore,
        labelStore,
        planningStore,
        notesStore,
        groupStore,
        onboardingStore,
        appcuesStore,
        settingsStore,
        homeStore,
        store,
        resultsStore,
        verdiepenStore,
        userStore,
        documentsStore,
        exportStore,
    }
}
