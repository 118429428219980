import { isSameDay } from 'date-fns'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

import { ActivityType } from '@/enums/ActivityType'
import { convertUTCtoLocal } from '@/helpers/convertTimezone'
import { IYearGroupLabel } from '@/interfaces/IGroup'
import { ILabelWithNotes, INoteIngeplandPlanning } from '@/interfaces/INote'
import { IPlanItemIngepland, ISchoolweken } from '@/interfaces/IPlanning'
import { groupService } from '@/services/groupService'
import { planningService } from '@/services/planningService'

import { notify } from '@kyvg/vue3-notification'
import { PlanItemType } from './../../enums/PlanItemType'
import { IActiviteitenCollectionPlanning, IAfspraakPost, IIngeplandeItems, ILabelKleur, ILesMovePost, ILesReeksMovePost, IMethodeBlokCollectionPlanning, IPlanItem } from './../../interfaces/IPlanning'

class PlanningState {
    schoolweken: ISchoolweken = {
        eersteSchooldag: new Date(),
        laatsteSchooldag: new Date(),
        weeknummerInformatie: [],
    }
    planning: IPlanItem[] = []
    ingeplandeItems: IIngeplandeItems = {
        afspraken: [],
        studiedagen: [],
        themadagen: [],
        toetsperiodes: [],
        vakanties: [],
        notities: [],
    }
    currentDateRange = {
        start: new Date(),
        end: new Date(),
    }
    methodeBlokken: IMethodeBlokCollectionPlanning[] = []
    yeargroups: IYearGroupLabel[] = []
    vakGebiedKleuren: ILabelKleur[] = []
    planningLoading = false
    templatesEnLessen: IActiviteitenCollectionPlanning[] = []
    labelsMetNotities: ILabelWithNotes[] = []
}

const getters: GetterTree<PlanningState, any> = {
    templatesEnLessen(state) {
        return () => state.templatesEnLessen
    },
    methodeBlokken(state) {
        return () => state.methodeBlokken
    },
    schoolweken(state) {
        return () => state.schoolweken
    },
    planning(state) {
        return () => state.planning
    },
    ingeplandeItems(state) {
        return () => state.ingeplandeItems
    },
    yeargroupsPlanning(state) {
        return () => state.yeargroups
    },
    availableYeargroupsPlanning(state) {
        return () =>
            state.yeargroups.sort((a, b) => {
                if (a.naam < b.naam) {
                    return -1
                }
                if (a.naam > b.naam) {
                    return 1
                }
                return 0
            })
    },
    currentDateRange(state) {
        return () => state.currentDateRange
    },
    visiblePlanning(state) {
        return () =>
            state.planning.filter((item) => {
                return new Date(item.datumTijd).getTime() <= state.currentDateRange.end.getTime() && new Date(item.datumTijd).getTime() >= state.currentDateRange.start.getTime() && state.yeargroups.filter((yeargroup) => yeargroup.selected).find((yeargroup) => yeargroup.labelId === item.jaargroepLabelId) ? true : false
            })
        // .filter((dag, index, array) =>
        //   index === array.findIndex((t) => {
        //     return new Date(t.datumTijd).getTime() === new Date(dag.datumTijd).getTime() && t.blockId === dag.blockId
        //   }))
    },
    visiblePlanItems(state) {
        const visibleAfspraken = state.ingeplandeItems.afspraken
            .filter((item) => {
                return state.currentDateRange.end.getTime() >= new Date(item.datumStart).getTime() && state.currentDateRange.start.getTime() <= new Date(item.datumEind).getTime() && state.yeargroups.filter((yeargroup) => yeargroup.selected).find((yeargroup) => item.afspraakDetails.jaargroepen.findIndex((jg) => jg === yeargroup.labelId) > -1) ? true : false
            })
            .map((item) => {
                return {
                    ...item,
                    datumStart: new Date(item.datumStart),
                    datumEind: new Date(item.datumEind),
                    planItemType: PlanItemType.AFSPRAAK,
                    backgroundColor: '#FFF8EE',
                    borderColor: '#605B70',
                    textColor: '#1C1634',
                }
            })
        const visibleStudiedagen = state.ingeplandeItems.studiedagen
            .filter((item) => {
                return state.currentDateRange.end.getTime() >= new Date(item.datumStart).getTime() && state.currentDateRange.start.getTime() <= new Date(item.datumEind).getTime()
            })
            .map((item) => {
                return {
                    ...item,
                    datumStart: new Date(item.datumStart),
                    datumEind: new Date(item.datumEind),
                    planItemType: PlanItemType.STUDIEDAG,
                    backgroundColor: '#F84280',
                    borderColor: '#F84280',
                    textColor: '#5578C0',
                }
            })
        const visibleThemadagen = state.ingeplandeItems.themadagen
            .filter((item) => {
                return state.currentDateRange.end.getTime() >= new Date(item.datumStart).getTime() && state.currentDateRange.start.getTime() <= new Date(item.datumEind).getTime()
            })
            .map((item) => {
                return {
                    ...item,
                    datumStart: new Date(item.datumStart),
                    datumEind: new Date(item.datumEind),
                    planItemType: PlanItemType.THEMADAG,
                    backgroundColor: '#9333ea',
                    borderColor: '#9333ea',
                    textColor: '#1C1634',
                }
            })
        const visibleToetsperiodes = state.ingeplandeItems.toetsperiodes
            .filter((item) => {
                return state.currentDateRange.end.getTime() >= new Date(item.datumStart).getTime() && state.currentDateRange.start.getTime() <= new Date(item.datumEind).getTime()
            })
            .map((item) => {
                return {
                    ...item,
                    datumStart: new Date(item.datumStart),
                    datumEind: new Date(item.datumEind),
                    planItemType: PlanItemType.TOETSPERIODE,
                    backgroundColor: '#5578C0',
                    borderColor: '#5578C0',
                    textColor: '#FFFFFF',
                }
            })
        const visibleVakanties = state.ingeplandeItems.vakanties
            .filter((item) => {
                return state.currentDateRange.end.getTime() >= new Date(item.datumStart).getTime() && state.currentDateRange.start.getTime() <= new Date(item.datumEind).getTime()
            })
            .map((item) => {
                return {
                    ...item,
                    datumStart: new Date(item.datumStart),
                    datumEind: new Date(item.datumEind),
                    heleDag: true,
                    planItemType: PlanItemType.VAKANTIE,
                    backgroundColor: '#9EDBD3',
                    borderColor: '#9EDBD3',
                    textColor: '#1C1634',
                }
            })
        const visibleNotities = state.ingeplandeItems.notities
            .filter((item) => {
                return state.currentDateRange.end.getTime() >= new Date(item.planningsdatum).getTime() && state.currentDateRange.start.getTime() <= new Date(item.planningsdatum).getTime()
            })
            .map((item) => {
                return {
                    ...item,
                    datumStart: item.planningsdatum ? new Date(item.planningsdatum) : null,
                    datumEind: item.planningsdatum ? new Date(item.planningsdatum) : null,
                    heleDag: true,
                    planItemType: PlanItemType.NOTITIE,
                    backgroundColor: `${item.onderwerpLabel.kleurHexCode}`,
                    borderColor: `${item.onderwerpLabel.kleurHexCode}`,
                    textColor: '#FFFFFF',
                } as INoteIngeplandPlanning
            })
        return () => ({
            afspraken: visibleAfspraken,
            studiedagen: visibleStudiedagen,
            themadagen: visibleThemadagen,
            toetsperiodes: visibleToetsperiodes,
            vakanties: visibleVakanties,
            notities: visibleNotities,
        })
    },
    vakgebiedKleuren(state) {
        return () => state.vakGebiedKleuren
    },
    calendarAttributesPlanning(state): any[] {
        const getDifferenceInDays = (date1: Date, date2: Date) => {
            const diffInMs = Math.abs((date2 as any) - (date1 as any))
            return diffInMs / (1000 * 60 * 60 * 24)
        }

        const holidays = state.ingeplandeItems.vakanties.map((day) => {
            const diff = getDifferenceInDays(convertUTCtoLocal(new Date(day.datumStart)), convertUTCtoLocal(new Date(day.datumEind)))
            if (diff < 1) {
                return {
                    key: day.titel,
                    highlight: {
                        fillMode: 'light',
                        color: 'teal',
                    },
                    popover: {
                        label: day.titel ? day.titel : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'holiday',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: convertUTCtoLocal(new Date(day.datumStart)) ? convertUTCtoLocal(new Date(day.datumStart)) : 'Geen startdatum toegevoegd',
                        datumEind: convertUTCtoLocal(new Date(day.datumEind)) ? convertUTCtoLocal(new Date(day.datumEind)) : 'Geen einddatum toegevoegd',
                    },
                    dates: convertUTCtoLocal(new Date(day.datumEind)),
                }
            } else {
                return {
                    key: day.titel,
                    highlight: {
                        start: { fillMode: 'light', color: 'teal' },
                        base: { fillMode: 'light', color: 'teal' },
                        end: { fillMode: 'light', color: 'teal' },
                    },
                    popover: {
                        label: day.titel ? day.titel : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'holiday',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: convertUTCtoLocal(new Date(day.datumStart)) ? convertUTCtoLocal(new Date(day.datumStart)) : 'Geen startdatum toegevoegd',
                        datumEind: convertUTCtoLocal(new Date(day.datumEind)) ? convertUTCtoLocal(new Date(day.datumEind)) : 'Geen einddatum toegevoegd',
                    },
                    dates: { start: convertUTCtoLocal(new Date(day.datumStart)), end: convertUTCtoLocal(new Date(day.datumEind)) },
                }
            }
        })

        const studyDays = state.ingeplandeItems.studiedagen.map((day) => {
            const diff = getDifferenceInDays(convertUTCtoLocal(new Date(day.datumStart)), convertUTCtoLocal(new Date(day.datumEind)))
            if (diff < 1) {
                return {
                    key: day.titel,
                    highlight: {
                        fillMode: 'light',
                        color: 'pink',
                    },
                    popover: {
                        label: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'studyday',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: convertUTCtoLocal(new Date(day.datumStart)) ? convertUTCtoLocal(new Date(day.datumStart)) : 'Geen startdatum toegevoegd',
                        datumEind: convertUTCtoLocal(new Date(day.datumEind)) ? convertUTCtoLocal(new Date(day.datumEind)) : 'Geen einddatum toegevoegd',
                    },
                    dates: convertUTCtoLocal(new Date(day.datumEind)),
                }
            } else {
                return {
                    key: day.titel,
                    highlight: {
                        start: { fillMode: 'light', color: 'pink' },
                        base: { fillMode: 'light', color: 'pink' },
                        end: { fillMode: 'light', color: 'pink' },
                    },
                    popover: {
                        label: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'studyday',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: convertUTCtoLocal(new Date(day.datumStart)) ? convertUTCtoLocal(new Date(day.datumStart)) : 'Geen startdatum toegevoegd',
                        datumEind: convertUTCtoLocal(new Date(day.datumEind)) ? convertUTCtoLocal(new Date(day.datumEind)) : 'Geen einddatum toegevoegd',
                    },
                    dates: { start: convertUTCtoLocal(new Date(day.datumStart)), end: convertUTCtoLocal(new Date(day.datumEind)) },
                }
            }
        })

        const tests = state.ingeplandeItems.toetsperiodes.map((day) => {
            const diff = getDifferenceInDays(convertUTCtoLocal(new Date(day.datumStart)), convertUTCtoLocal(new Date(day.datumEind)))
            if (diff < 1) {
                return {
                    key: day.titel,
                    highlight: {
                        fillMode: 'light',
                        color: 'blue',
                    },
                    popover: {
                        label: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'test',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: convertUTCtoLocal(new Date(day.datumStart)) ? convertUTCtoLocal(new Date(day.datumStart)) : 'Geen startdatum toegevoegd',
                        datumEind: convertUTCtoLocal(new Date(day.datumEind)) ? convertUTCtoLocal(new Date(day.datumEind)) : 'Geen einddatum toegevoegd',
                    },
                    dates: convertUTCtoLocal(new Date(day.datumEind)),
                }
            } else {
                return {
                    key: day.titel,
                    highlight: {
                        start: { fillMode: 'light', color: 'blue' },
                        base: { fillMode: 'light', color: 'blue' },
                        end: { fillMode: 'light', color: 'blue' },
                    },
                    popover: {
                        label: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'test',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: convertUTCtoLocal(new Date(day.datumStart)) ? convertUTCtoLocal(new Date(day.datumStart)) : 'Geen startdatum toegevoegd',
                        datumEind: convertUTCtoLocal(new Date(day.datumEind)) ? convertUTCtoLocal(new Date(day.datumEind)) : 'Geen einddatum toegevoegd',
                    },
                    dates: { start: convertUTCtoLocal(new Date(day.datumStart)), end: convertUTCtoLocal(new Date(day.datumEind)) },
                }
            }
        })

        const activities = state.ingeplandeItems.themadagen.map((day) => {
            const diff = getDifferenceInDays(convertUTCtoLocal(new Date(day.datumStart)), convertUTCtoLocal(new Date(day.datumEind)))

            if (diff < 1) {
                return {
                    key: day.titel,
                    highlight: {
                        fillMode: 'light',
                        color: 'purple',
                    },
                    popover: {
                        label: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'activity',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: convertUTCtoLocal(new Date(day.datumStart)) ? convertUTCtoLocal(new Date(day.datumStart)) : 'Geen startdatum toegevoegd',
                        datumEind: convertUTCtoLocal(new Date(day.datumEind)) ? convertUTCtoLocal(new Date(day.datumEind)) : 'Geen einddatum toegevoegd',
                    },
                    dates: convertUTCtoLocal(new Date(day.datumEind)),
                }
            } else {
                return {
                    key: day.titel,
                    highlight: {
                        start: { fillMode: 'light', color: 'purple' },
                        base: { fillMode: 'light', color: 'purple' },
                        end: { fillMode: 'light', color: 'purple' },
                    },
                    popover: {
                        label: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'activity',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: convertUTCtoLocal(new Date(day.datumStart)) ? convertUTCtoLocal(new Date(day.datumStart)) : 'Geen startdatum toegevoegd',
                        datumEind: convertUTCtoLocal(new Date(day.datumEind)) ? convertUTCtoLocal(new Date(day.datumEind)) : 'Geen einddatum toegevoegd',
                    },
                    dates: { start: convertUTCtoLocal(new Date(day.datumStart)), end: convertUTCtoLocal(new Date(day.datumEind)) },
                }
            }
        })

        return () => [...holidays, ...studyDays, ...activities, ...tests]
    },
    planningLoading(store): boolean {
        return () => store.planningLoading
    },
    labelsMetNotities(store): ILabelWithNotes[] {
        return () => store.labelsMetNotities
    },
}

const mutations: MutationTree<PlanningState> = {
    cleanPlanning(state) {
        state.ingeplandeItems = {
            afspraken: [],
            studiedagen: [],
            themadagen: [],
            toetsperiodes: [],
            vakanties: [],
            notities: [],
        }

        state.planning = []
        state.templatesEnLessen = []
    },
    setTemplatesEnLessen(state, payload: IActiviteitenCollectionPlanning[]) {
        state.templatesEnLessen = payload
    },
    setMethodeBlokken(state, payload: IMethodeBlokCollectionPlanning[]) {
        state.methodeBlokken = payload
    },
    setPlanningLoading(state, payload: boolean) {
        state.planningLoading = payload
    },
    setSchoolweken(state, payload: ISchoolweken) {
        state.schoolweken = payload
    },
    setPlanning(state, payload: IPlanItem[]) {
        state.planning = payload
    },
    setIngeplandeItems(state, payload: IIngeplandeItems) {
        state.ingeplandeItems = payload
    },
    setCurrentDateRange(state, payload: any) {
        state.currentDateRange = payload
    },
    setVakgebiedKleuren(state, payload: ILabelKleur[]) {
        state.vakGebiedKleuren = payload
    },
    setYeargroupLabels(state, payload: IYearGroupLabel[]) {
        state.yeargroups = payload
    },
    resetYeargroupLabels(state) {
        state.yeargroups = state.yeargroups.map((yeargroup) => {
            return {
                ...yeargroup,
                selected: true,
            }
        })
    },
    setLabelsMetNotities(state, payload: ILabelWithNotes[]) {
        state.labelsMetNotities = payload
    },
}

const actions: ActionTree<PlanningState, any> = {
    getMethodeBlokken({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            planningService
                .getMethodeblokken(rootState.groups.selectedGroup.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                        commit('setMethodeBlokken', data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    calculateJaarplanning({ rootState }) {
        return new Promise((resolve, reject) => {
            planningService
                .calculatePlanning(rootState.groups.selectedGroup.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        notify({
                            group: 'requests',
                            title: 'Gelukt',
                            type: 'success',
                            text: `Basisrooster opgeslagen en jaarplanning berekend`,
                        })
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    getSchoolweken({ commit }) {
        return new Promise((resolve, reject) => {
            planningService
                .getSchoolweken()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                        commit('setSchoolweken', data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    getPlanning({ commit, dispatch, rootState, state }) {
        return new Promise((resolve, reject) => {
            planningService
                .getPlanning(rootState.groups.selectedGroup.id, state.currentDateRange.start.toISOString(), state.currentDateRange.end.toISOString())
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        if (import.meta.env.PROD) {
                            const trackingObj: any = {
                                stamgroep: rootState.groups.selectedGroup.naam,
                            }

                            const itemsToday = data.data.filter((item: IPlanItem) => isSameDay(new Date(item.datumTijd), new Date()))

                            itemsToday.forEach((item: IPlanItem) => {
                                const titel = item.naamVak ?? item.naamBasisActiviteit
                                const methodeId = item.methodeId ?? 'eigen_invulling'

                                if (titel) {
                                    const naam = `Invulling_${titel.replaceAll(' ', '_')}`
                                    const methode = `Methode_${titel.replaceAll(' ', '_')}`
                                    if (item.activiteitType === ActivityType.EigenInvulling || item.activiteitType === ActivityType.ZelfBedachtMetEigenInvulling || (item.activiteitType === ActivityType.ZelfBedacht && !item.lesplanningTemplateId)) {
                                        trackingObj[naam] = 'eigen_invulling'
                                    } else {
                                        trackingObj[naam] = item.methodeBlok && item.lesId ? `${item.methodeBlok}, ${item.lesId}` : null
                                    }
                                    if (trackingObj[methode]) {
                                        if (typeof trackingObj[methode] === 'number' || typeof trackingObj[methode] === 'string') {
                                            if (trackingObj[methode] !== methodeId) {
                                                trackingObj[methode] = [trackingObj[methode], methodeId]
                                            }
                                        } else {
                                            if (!trackingObj[methode].find((m: number | string) => m === methodeId)) {
                                                trackingObj[methode] = [...trackingObj[methode], methodeId]
                                            }
                                        }
                                    } else {
                                        trackingObj[methode] = methodeId
                                    }
                                }
                            })
                            dispatch('appcuesIdentify', trackingObj)
                            trackingObj.evtName = 'invulling'
                            dispatch('appcuesTrack', trackingObj)
                        }

                        resolve(data.data)
                        commit('setPlanning', data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    getPlanningFull({ commit, dispatch, rootState, state }) {
        return new Promise((resolve, reject) => {
            planningService
                .getPlanning(rootState.groups.selectedGroup.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    getIngeplandeItems({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            planningService
                .getIngeplandeItems(rootState.groups.selectedGroup.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                        commit('setIngeplandeItems', data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    getLabelKleuren({ commit }) {
        return new Promise((resolve, reject) => {
            planningService
                .getLabelColors()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                        commit('setVakgebiedKleuren', data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    getYeargroupLabels({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            groupService
                .getYeargroups(rootState.groups.selectedGroup.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        const labels: IYearGroupLabel[] = (data.data as IYearGroupLabel[]).map((label) => {
                            return { ...label, selected: true }
                        })
                        commit('setYeargroupLabels', labels)
                        resolve(labels)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    postPlanitem({ commit }, payload: IPlanItem) {
        return new Promise((resolve, reject) => {
            const les = {
                titel: payload.eigenTitel,
                omschrijving: payload.eigenOmschrijving,
                eigenInvullingTekst: payload.eigenInvullingTekst,
            }

            planningService
                .postPlanItem(payload.stamgroepId, payload.jaargroepLabelId, payload.id, les)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    planItemDoorschuiven({ commit }, payload: IPlanItem) {
        return new Promise((resolve, reject) => {
            const les: ILesMovePost = {
                lesId: payload.id,
                nieuweDatum: payload.nieuweDatum || new Date().toISOString(),
                nieuweEindDatum: payload.nieuweEindDatum || new Date().toISOString(),
                stamgroepId: payload.stamgroepId,
            }
            planningService
                .postEnkelelesDoorschuiven(les)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    planItemReeksDoorschuiven({ commit }, payload: IPlanItem) {
        return new Promise((resolve, reject) => {
            const les: ILesReeksMovePost = {
                lesId: payload.id,
                aantalWeken: 1,
                stamgroepId: payload.stamgroepId,
            }
            planningService
                .postLesreeksDoorschuiven(les)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    planItemReeksCorrigeren({ commit }, payload: IPlanItem) {
        return new Promise((resolve, reject) => {
            const les: ILesReeksMovePost = {
                lesId: payload.id,
                aantalWeken: 1,
                stamgroepId: payload.stamgroepId,
            }
            planningService
                .postLesreeksDoorschuiven(les)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    planItemCorrigeren({ commit, dispatch, rootState }, payload: IPlanItem) {
        return new Promise((resolve, reject) => {
            planningService
                .postPlanItemCorrigeren(rootState.groups.selectedGroup.id, payload.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    planItemVerwijderen({ commit }, payload: IPlanItem) {
        return new Promise((resolve, reject) => {
            planningService
                .deleteLes(payload.id, payload.stamgroepId)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    postAfspraak({ rootState }, payload: IAfspraakPost) {
        return new Promise((resolve, reject) => {
            planningService
                .postAfspraak(rootState.groups.selectedGroup.id, payload)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan met het plannen van de afspraak. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    putAfspraak({ rootState }, payload: IAfspraakPost) {
        return new Promise((resolve, reject) => {
            planningService
                .putAfspraak(rootState.groups.selectedGroup.id, payload)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan met het bewerken van de afspraak. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    deleteAfspraak({ rootState }, payload: number) {
        return new Promise((resolve, reject) => {
            planningService
                .deleteAfspraak(rootState.groups.selectedGroup.id, payload)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan met het verwijderen van de afspraak. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    deleteAfspraakAfbeelding({ rootState }, afspraakid: number) {
        return new Promise((resolve, reject) => {
            planningService
                .deleteAfspraakAfbeelding(rootState.groups.selectedGroup.id, afspraakid)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan met het verwijderen van de afbeelding. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    getTemplatesEnLessen({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            planningService
                .getTemplatesEnLessen(rootState.groups.selectedGroup.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setTemplatesEnLessen', data.data)
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    getActiviteitDetails({ rootState }, lesId: number) {
        return new Promise((resolve, reject) => {
            planningService
                .getActiviteitDetails(lesId, rootState.groups.selectedGroup.id)
                .then((data) => {
                    resolve(data.data)
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    getLabelsMetNotities({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            planningService
                .getLabelsMetNotities(rootState.groups.selectedGroup.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setLabelsMetNotities', data.data)
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    getToekomstigeLessenVoorVak({ rootState }, vakLabelId: number) {
        return new Promise((resolve, reject) => {
            planningService
                .getToekomstigeLessenVoorVak(rootState.groups.selectedGroup.id, vakLabelId)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    getIngeplandeAfsprakenVoorVak({ rootState }, vakLabelId: number) {
        return rootState.planning.ingeplandeItems.afspraken.filter((afspraak: IPlanItemIngepland) => afspraak.afspraakDetails.vakgebiedLabelId === vakLabelId)
    },
}

export default {
    state: new PlanningState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
