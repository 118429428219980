<script setup lang="ts">
import { format, isAfter } from 'date-fns'
import { nl as nlLocale } from 'date-fns/locale'
import { computed, onMounted, ref, watch } from 'vue'

import { useStore } from '@/composables/useStore'
import { convertUTCtoLocal } from '@/helpers/convertTimezone'
import { uuidv4 } from '@/helpers/uuidv4'
import { IExtendedNoteInfo, INotePlanLes, INoteWithLabel } from '@/interfaces/INote'
import { ILabel, IPlanItem, IPlanItemIngepland } from '@/interfaces/IPlanning'

interface Props {
    planOnly: boolean
}

const props = defineProps<Props>()

const { notesStore, planningStore, labelStore, groupStore } = useStore()

const emit = defineEmits<{
    (event: 'schedule:note-lessons', payload: INotePlanLes[]): void
    (event: 'schedule:note-les', payload: INotePlanLes[]): void
    (event: 'schedule:note-vak', payload: ILabel): void
    (event: 'update:note', payload: INotePlanLes[]): void
    (event: 'close:plan-note'): void
}>()

const vakken = ref()
const lessen = ref<null | INotePlanLes[]>(null)
const selectedVak = ref<null | ILabel>(null)
const selectedLessen = ref<INotePlanLes[]>([])
const vakkenLoading = ref(false)
const lessenLoading = ref(false)

const sortedLesOptions = computed(() => {
    if (lessen.value === null) {
        return []
    } else {
        const lessenToSort = [...lessen.value]
        return lessenToSort.sort((a: INotePlanLes, b: INotePlanLes) => (isAfter(new Date(a.datumTijd), new Date(b.datumTijd)) ? 1 : -1))
    }
})

const selectedLessonCount = computed(() => {
    return selectedLessen.value.length
})

const getLessenVoorVak = async () => {
    if (selectedVak.value) {
        lessenLoading.value = true
        const planItemsVoorVak = await planningStore.actions.getToekomstigeLessenVoorVak(selectedVak.value.labelId)
        const afsprakenVoorVak = await planningStore.actions.getIngeplandeAfsprakenVoorVak(selectedVak.value.labelId)
        const newLessen: INotePlanLes[] = [...mapPlanItemIngeplandToNotePlanLes(planItemsVoorVak), ...mapAfspraakToNotePlanLes(afsprakenVoorVak)]

        lessen.value = newLessen

        lessenLoading.value = false
    }

    return new Promise((resolve, reject) => {
        if (lessen.value) {
            resolve(lessen.value)
        } else {
            reject('No lessons found')
        }
    })
}

const mapPlanItemIngeplandToNotePlanLes = (items: IPlanItem[]): INotePlanLes[] => {
    return items.map(
        (item: IPlanItem): INotePlanLes =>
            ({
                isAfspraak: false,
                ingeplandeActiviteitId: item.id,
                vakgebiedId: item.vakgebiedId,
                activiteitLabelId: item.activiteitLabelId,
                naamVak: item.naamVak,
                naamMethode: item.naamMethode,
                naamLes: item.naamLes,
                datumTijd: item.datumTijd,
                methodeId: item.methodeId,
            }) as INotePlanLes
    )
}

const mapAfspraakToNotePlanLes = (items: IPlanItemIngepland[]): INotePlanLes[] => {
    return items.map(
        (item: IPlanItemIngepland): INotePlanLes =>
            ({
                isAfspraak: true,
                ingeplandeActiviteitId: item.id,
                vakgebiedId: item.afspraakDetails.vakgebiedLabelId,
                activiteitLabelId: item.afspraakDetails.activiteitLabelId,
                naamVak: getNaamVakByLabelId(item.afspraakDetails.vakgebiedLabelId),
                naamMethode: item.afspraakDetails.methodeNaam,
                naamLes: item.titel,
                datumTijd: item.datumStart,
                methodeId: item.afspraakDetails.methodeBlokId,
            }) as INotePlanLes
    )
}

const getNaamVakByLabelId = (labelId: number): string => {
    const vakLabel = labelStore.getters.value.vakLabels.find((item: any) => item.labelId === labelId)
    if (vakLabel) {
        return vakLabel.naam
    } else {
        return ''
    }
}

const confirmLessons = () => {
    if (selectedLessen.value.length) {
        if (selectedLessen.value.length === 1) {
            emit('schedule:note-les', selectedLessen.value)
        } else {
            emit('schedule:note-lessons', selectedLessen.value)
        }
        emit('close:plan-note')
    }
}

const confirmVak = () => {
    if (selectedVak.value) {
        emit('schedule:note-vak', selectedVak.value)
        emit('close:plan-note')
    }
}

const lessenFilter = (options: INotePlanLes[], search: string) => {
    return options.filter((option: INotePlanLes) => {
        const toSearch = `${option.naamLes}${option.naamMethode}${option.naamVak}${format(new Date(option.datumTijd), 'EEEE d MMMM HH:mm', { locale: nlLocale }).replace(/ /g, '')}}`
        return toSearch.toLowerCase().includes(search.replace(/ /g, '').toLowerCase())
    })
}

const confirmLessonPlanOnly = () => {
    if (selectedLessen.value.length) {
        emit('update:note', selectedLessen.value)
        emit('close:plan-note')
    }
}

const cancel = () => {
    emit('close:plan-note')
}

const removeSelectedLes = (index: number) => {
    selectedLessen.value.splice(index, 1)
}

const selectLesNaamPlaceholder = computed(() => {
    if (selectedVak.value && !selectedLessen.value.length) {
        return '(optioneel) Selecteer les(sen)'
    } else {
        return 'Selecteer eerst een vak'
    }
})

const getVakken = async () => {
    vakkenLoading.value = true

    const [planningFull, ingeplandeItems] = await Promise.all([planningStore.actions.getPlanningFull(), planningStore.actions.getIngeplandeItems()])

    const afsprakenVakLabel = [...new Set(ingeplandeItems.afspraken.map((item: any) => item.afspraakDetails.vakgebiedLabelId))]
    const vakLabelsPlanning = [...new Set(planningFull.map((item: any) => item.vakgebiedId))]

    const allLabels = [...new Set([...afsprakenVakLabel, ...vakLabelsPlanning])]

    vakken.value = labelStore.getters.value.vakLabels.filter((item: any) => allLabels.includes(item.labelId))

    vakkenLoading.value = false
}

const setSelectedVak = (note: INoteWithLabel) => {
    const currentNoteVakgebiedId = note.vakgebiedLabelId

    if (currentNoteVakgebiedId) {
        selectedVak.value = vakken.value.find((item: any) => item.labelId === currentNoteVakgebiedId) ?? null
    }
}

const setSelectedVakExtendedInfo = (extendedNoteInfo: IExtendedNoteInfo) => {
    let vakId: null | number = null
    if (extendedNoteInfo.afspraken.length) {
        vakId = extendedNoteInfo.afspraken[0].vakgebiedId
    }
    if (extendedNoteInfo.ingeplandeActiviteiten.length) {
        vakId = extendedNoteInfo.ingeplandeActiviteiten[0].vakgebiedId
    }

    if (vakId) {
        selectedVak.value = vakken.value.find((item: any) => item.labelId === vakId) ?? null
    }
}

const setSelectedLessenExtendedInfo = (extendedNoteInfo: IExtendedNoteInfo) => {
    const selectedLesIds = [...extendedNoteInfo.afspraken.map((item: any) => item.id), ...extendedNoteInfo.ingeplandeActiviteiten.map((item: any) => item.id)]
    if (lessen.value && lessen.value.length) {
        selectedLessen.value = lessen.value.filter((les) => selectedLesIds.includes(les.ingeplandeActiviteitId))
    }
}

const setSelectedLessenCurrentNote = (note: INoteWithLabel) => {
    const selectedLesIds = [...note.afspraken, ...note.ingeplandeActiviteiten]
    if (lessen.value && lessen.value.length) {
        selectedLessen.value = lessen.value.filter((les) => selectedLesIds.includes(les.ingeplandeActiviteitId))
    }
}

const setSelectedValues = async () => {
    const currentNote = notesStore.getters.value.getCurrentNote
    if (currentNote) {
        // Als alleen vak geselecteerd
        if (currentNote.vakgebiedLabelId) {
            setSelectedVak(currentNote)
            getLessenVoorVak()
        }

        // Als les(sen) geselecteerd
        if (currentNote.afspraken.length || currentNote.ingeplandeActiviteiten.length) {
            if (notesStore.getters.value.getCurrentNoteExtendedInfo) {
                const extendedNoteInfo = notesStore.getters.value.getCurrentNoteExtendedInfo
                setSelectedVakExtendedInfo(extendedNoteInfo)
                await getLessenVoorVak()
                setSelectedLessenExtendedInfo(extendedNoteInfo)
            } else if (notesStore.getters.value.getNoteMomentVakgebied && notesStore.getters.value.getCurrentNote) {
                const vakId = notesStore.getters.value.getNoteMomentVakgebied
                selectedVak.value = vakken.value.find((item: any) => item.labelId === vakId) ?? null
                await getLessenVoorVak()
                setSelectedLessenCurrentNote(notesStore.getters.value.getCurrentNote)
            }
        }
    }
}

onMounted(async () => {
    await getVakken()
    setSelectedValues()
})

watch(
    () => selectedVak.value,
    () => {
        selectedLessen.value = []
        getLessenVoorVak()
    }
)
</script>

<template>
    <div class="schedule-note__lesson d-flex flex-column">
        <div class="d-flex align-items-center mb-4">
            <label class="mb-0 mr-3">Vak:</label>
            <v-select
                class="filter-select"
                :clearable="false"
                :name="'vak-select'"
                :options="vakken"
                label="naam"
                :searchable="true"
                :disabled="vakkenLoading"
                v-model="selectedVak"
                :loading="vakkenLoading"
                :placeholder="'vak'"
            >
                <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                        <svg
                            class="feather feather-chevron-down"
                            fill="none"
                            height="24"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <polyline
                                points="6 9 12 15 18 9"
                                stroke-width="1"
                            ></polyline>
                        </svg>
                    </span>
                </template>
            </v-select>
        </div>
        <div
            class="schedule-note__lesson__select-lesson d-flex align-items-center"
            :class="[selectedLessen.length ? 'mb-4' : 'mb-10']"
        >
            <label class="mb-0 mr-3">Les:</label>
            <v-select
                label="naamLes"
                class="filter-select"
                :clearable="selectedVak && !vakkenLoading"
                :name="'vak-select'"
                :options="sortedLesOptions"
                :searchable="true"
                v-model="selectedLessen"
                :disabled="!selectedVak || lessenLoading"
                :placeholder="selectLesNaamPlaceholder"
                :loading="!!(lessenLoading && selectedVak)"
                multiple
                :filter="lessenFilter"
                :closeOnSelect="false"
            >
                <template #option="{ datumTijd, naamMethodegroep, naamLes, ingeplandeActiviteitId }">
                    <div class="d-flex justify-content-between">
                        <div class="schedule-note__lesson__select-lesson__option flex-1 d-flex flex-column">
                            <div class="schedule-note__lesson__select-lesson__option__date">{{ format(convertUTCtoLocal(new Date(datumTijd)), 'EEEE d MMMM HH:mm', { locale: nlLocale }) }}</div>
                            <div class="schedule-note__lesson__select-lesson__option__method">{{ naamMethodegroep }}</div>
                            <div class="schedule-note__lesson__select-lesson__option__lesson">{{ naamLes }}</div>
                        </div>
                        <div
                            class="schedule-note__lesson__select-lesson__option__check"
                            v-if="selectedLessen.find((selectedLes) => selectedLes.ingeplandeActiviteitId === ingeplandeActiviteitId)"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                            >
                                <path
                                    d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                                    fill="rgba(71,49,135,1)"
                                ></path>
                            </svg>
                        </div>
                    </div>
                </template>
                <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                        <svg
                            class="feather feather-chevron-down"
                            fill="none"
                            height="24"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <polyline
                                points="6 9 12 15 18 9"
                                stroke-width="1"
                            ></polyline>
                        </svg>
                    </span>
                </template>
                <template #selected-option="{ methodeId, naamLes, datumTijd }"></template>
                <template #selected-option-container="{ methodeId, naamLes, datumTijd }"><div></div></template>
                <template #search="{ attributes, events }">
                    <input
                        class="vs__search"
                        v-bind="attributes"
                        v-on="events"
                        :placeholder="selectedLessonCount ? `${selectedLessonCount} les(sen) geselecteerd` : selectLesNaamPlaceholder"
                    />
                </template>
            </v-select>
        </div>
        <div class="schedule-note__lesson__selected-lessons mb-4">
            <div
                v-for="(les, index) in selectedLessen"
                :key="les.naamVak + uuidv4()"
                class="schedule-note__lesson__selected-lessons__lesson"
            >
                <div class="schedule-note__lesson__selected-lessons__lesson__data">
                    <div class="schedule-note__lesson__selected-lessons__lesson__data__date">{{ format(convertUTCtoLocal(new Date(les.datumTijd)), 'EEEE d MMMM HH:mm', { locale: nlLocale }) }}</div>
                    <div class="schedule-note__lesson__selected-lessons__lesson__data__method">{{ les.naamMethode }}</div>
                    <div class="schedule-note__lesson__selected-lessons__lesson__data__lesson">{{ les.naamLes }}</div>
                </div>
                <button
                    class="btn btn-active-tag d-flex"
                    @click="removeSelectedLes(index)"
                >
                    <div class="active-tag-icon d-flex">
                        <svg
                            class="feather feather-x-circle"
                            fill="#A69BD1"
                            height="20"
                            stroke="#FFFFFF"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 21"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="12"
                                cy="12"
                                r="10"
                            ></circle>
                            <line
                                x1="15"
                                x2="9"
                                y1="9"
                                y2="15"
                            ></line>
                            <line
                                x1="9"
                                x2="15"
                                y1="9"
                                y2="15"
                            ></line>
                        </svg>
                    </div>
                </button>
            </div>
        </div>
        <div class="schedule-note__lesson__buttons d-flex flex-column align-items-center">
            <button
                v-if="planOnly"
                :disabled="!selectedLessen.length"
                class="schedule-note__lesson__buttons__save btn btn-purple-gradient w-100 mb-2"
                @click="confirmLessonPlanOnly"
            >
                Inplannen voor deze les{{ selectedLessen.length > 1 ? 'sen' : '' }}
            </button>
            <button
                v-if="planOnly"
                class="schedule-note__lesson__buttons__back btn text-primary"
                @click="cancel"
            >
                Annuleren
            </button>
            <button
                v-else-if="selectedLessen.length"
                :disabled="!selectedVak"
                class="schedule-note__lesson__buttons__save btn btn-purple-gradient w-100 mb-2"
                @click="confirmLessons"
            >
                Selecteer les{{ selectedLessen.length > 1 ? 'sen' : '' }}
            </button>
            <button
                v-else
                :disabled="!selectedVak"
                class="schedule-note__lesson__buttons__save btn btn-purple-gradient w-100 mb-2"
                @click="confirmVak"
            >
                Selecteer vak
            </button>
            <button
                class="schedule-note__lesson__buttons__back btn text-primary"
                v-if="!planOnly"
                @click="cancel"
            >
                Terug zonder les te selecteren
            </button>
        </div>
    </div>
</template>
