import axios from 'axios'  
export default class GroupService {
    getGroups() {
        return axios.get(`api/Frontend/Groep`);
    }
    getYeargroups(groupId: number) {
        return axios.get(`api/Frontend/Groep/Jaargroepen/${groupId}`);
    }
    getCandidatesByGroupId(groupId: number) {
        return axios.get(`api/Frontend/Groep/${groupId}`);
    }
}

export const groupService = new GroupService();