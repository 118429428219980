<script setup lang="ts">
import { format, isSameDay } from 'date-fns'
import { nl as nlLocale } from 'date-fns/locale'
import { computed, onMounted, ref } from 'vue'

import { useStore } from '@/composables/useStore'
import { convertLocalToUTC } from '@/helpers/convertTimezone'
import { INoteWithLabel } from '@/interfaces/INote'

interface Props {
    planOnly: boolean
}

const props = defineProps<Props>()

const datum = ref<Date | null>(null)

const emit = defineEmits<{
    (event: 'schedule:note', payload: Date): void
    (event: 'update:note', payload: Date): void
    (event: 'close:plan-note'): void
}>()

const { notesStore } = useStore()

const getters = computed(() => {
    return {
        getCurrentNote: notesStore.getters.value.getCurrentNote as INoteWithLabel | null,
    }
})

const commits = () => {
    return {}
}

const actions = () => {
    return {}
}

const selectedDateText = computed(() => {
    if (datum.value) {
        if (isSameDay(new Date(datum.value), new Date())) return 'vandaag'
        return format(new Date(datum.value), 'EEEE d MMMM', { locale: nlLocale })
    } else {
        return 'Selecteer een datum'
    }
})

const selectDate = () => {
    if (datum.value) {
        emit('schedule:note', datum.value)
        emit('close:plan-note')
    }
}

const selectDatePlanOnly = () => {
    if (datum.value) {
        emit('update:note', datum.value)
        emit('close:plan-note')
    }
}

const cancel = () => {
    emit('close:plan-note')
}

const cancelText = computed(() => {
    if (props.planOnly) {
        return 'Annuleren'
    } else {
        return 'Terug zonder datum te selecteren'
    }
})

const formattedDate = computed({
    get() {
        return datum.value
    },
    set(value) {
        if (value) {
            datum.value = convertLocalToUTC(value)
        }
    },
})

onMounted(() => {
    if (getters.value.getCurrentNote && getters.value.getCurrentNote.planningsdatum) {
        datum.value = getters.value.getCurrentNote.planningsdatum
    }
})
</script>

<template>
    <div class="container">
        <h3 class="schedule-note__date__title mb-6">{{ selectedDateText }}</h3>
        <v-date-picker
            class="schedule-note__date__picker w-100 mb-10"
            is-required
            mode="date"
            v-model="formattedDate"
            locale="nl"
            :min-date="new Date()"
        >
        </v-date-picker>
        <div class="schedule-note__date__buttons d-flex flex-column align-items-center">
            <button
                v-if="planOnly"
                :disabled="!datum"
                class="schedule-note__lesson__buttons__save btn btn-purple-gradient w-100 mb-2"
                @click="selectDatePlanOnly"
            >
                Inplannen op deze dag
            </button>
            <button
                v-else
                class="schedule-note__date__buttons__save btn btn-purple-gradient w-100 mb-2"
                @click="selectDate"
                :disabled="!datum"
            >
                Selecteer datum
            </button>
            <button
                class="schedule-note__date__buttons__back btn text-primary"
                @click="cancel"
            >
                {{ cancelText }}
            </button>
        </div>
    </div>
</template>
