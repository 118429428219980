<template>
    <div
        class="editor"
        v-if="editor"
    >
        <rte-menu
            :editor="editor"
            class="editor__header"
        />
        <editor-content
            :editor="editor"
            class="rte"
        />
        <div class="editor__footer"></div>
    </div>
</template>

<script lang="ts" setup>
import { PluginKey } from 'prosemirror-state'
import tippy from 'tippy.js'
import { onBeforeUnmount, onMounted, watch } from 'vue'

import MentionList from '@/components/MentionList.vue'
import RteMenu from '@/components/RteMenu.vue'
import { MentionCustom } from '@/helpers/mention'
import { TagCustom } from '@/helpers/tag'
import { INoteWithLabel, ITagVM } from '@/interfaces/INote'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import { EditorContent, VueRenderer, useEditor } from '@tiptap/vue-3'

const Tagging = TagCustom

const Mentioning = MentionCustom

const props = defineProps<{
    placeholder?: string
    tags?: ITagVM[]
    note?: INoteWithLabel
}>()

const modelValue = defineModel({ default: '' })

const editor = useEditor({
    extensions: [
        StarterKit.configure({
            heading: false,
        }),
        Link.configure({
            openOnClick: false,
        }),
        Highlight,
        Underline,
        TextStyle,
        Placeholder.configure({
            placeholder: props.placeholder,
        }),
        Tagging.configure({
            HTMLAttributes: {
                class: 'tag',
            },
            suggestion: {
                char: '#',
                pluginKey: new PluginKey('tag'),
                items: ({ query }) => {
                    const tags = props.tags?.filter((item) => item.type === '#' && item.name.toLowerCase().startsWith(query.toLowerCase())) ?? []
                    const tagsUnique = tags.filter((item, index) => tags.findIndex((tag) => tag.id === item.id) === index)
                    const tagsSlice = tagsUnique.slice(0, 15)
                    const potentialMatch = props.tags?.find((item) => item.type === '#' && item.name.toLowerCase() === query.toLowerCase())
                    const customtag = {
                        id: potentialMatch?.id || -1,
                        name: query,
                        type: '#',
                    }
                    return [...tagsSlice, customtag]
                },
                render: () => {
                    let component: any
                    let popup: any

                    return {
                        onStart: (props: any) => {
                            component = new VueRenderer(MentionList, {
                                props: props,
                                editor: editor.value!,
                            })

                            popup = tippy('body', {
                                getReferenceClientRect: props.clientRect,
                                appendTo: () => document.body,
                                content: component.element,
                                showOnCreate: true,
                                interactive: true,
                                trigger: 'manual',
                                placement: 'bottom-start',
                                theme: 'light',
                                arrow: false,
                            })
                        },
                        onUpdate(props) {
                            component.updateProps(props)

                            popup[0].setProps({
                                getReferenceClientRect: props.clientRect,
                            })
                        },
                        onKeyDown(props) {
                            return component.ref?.onKeyDown(props)
                        },
                        onExit() {
                            popup[0].destroy()
                            component.destroy()
                        },
                    }
                },
            },
        }),
        Mentioning.configure({
            HTMLAttributes: {
                class: 'mention',
            },
            suggestion: {
                char: '@',
                pluginKey: new PluginKey('mention'),
                items: ({ query }) => {
                    return props.tags?.filter((item) => item.type === '@' && item.name.toLowerCase().startsWith(query.toLowerCase())).slice(0, 15) ?? []
                },
                render: () => {
                    let component: any
                    let popup: any

                    return {
                        onStart: (props: any) => {
                            component = new VueRenderer(MentionList, {
                                props: props,
                                editor: editor.value!,
                            })

                            popup = tippy('body', {
                                getReferenceClientRect: props.clientRect,
                                appendTo: () => document.body,
                                content: component.element,
                                showOnCreate: true,
                                interactive: true,
                                trigger: 'manual',
                                placement: 'bottom-start',
                                theme: 'light',
                                arrow: false,
                            })
                        },
                        onUpdate(props) {
                            component.updateProps(props)

                            popup[0].setProps({
                                getReferenceClientRect: props.clientRect,
                            })
                        },
                        onKeyDown(props) {
                            return component.ref?.onKeyDown(props)
                        },
                        onExit() {
                            popup[0].destroy()
                            component.destroy()
                        },
                    }
                },
            },
        }),
    ],
    content: modelValue.value,
    onUpdate: () => {
        modelValue.value = editor.value?.getHTML() ?? ''
    },
})

onMounted(() => {
    watch(
        () => modelValue.value,
        (newVal) => {
            const isSame = editor.value?.getHTML() === newVal
            if (!isSame) {
                editor.value?.commands.setContent(newVal ?? '', false)
            }
        },
        {
            immediate: true,
        }
    )
})

onBeforeUnmount(() => {
    editor.value?.destroy()
})
</script>
<style lang="scss">
.tippy-content {
    padding: 0 !important;
}
</style>
